import React from "react";
import "../assets/css/header.css";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <nav className="navbar navbar-light header">
      <div className="container">
        <div></div>
        <div className="nav-logo">
          <Link to="/" className="navbar-brand">
            <img className="logo" src="/assets/img/logo.svg" alt="logo" />
          </Link>
        </div>
        <div className="nav-links">
          <a
            href="https://blog.easyapp.ai/"
            className="nav-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blog
          </a>
        </div>
      </div>
    </nav>
  );
}
